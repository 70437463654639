.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282A32;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header p {
  font-family: "Russo One";
  font-size: calc(10px + 2vmin);
}